import React from 'react';

import ProductCard from './productCard';
import { IProduct } from './customiseVoucherModal';

const GiftVoucherProducts = ({ products }: { products: { node: IProduct }[] }) => {
  return (
    <div className="card-wrapper w-full">
      {products.map(p => (
        <div key={p.node.id} className="lg:w-1/3 p-2  flex-center">
          <ProductCard product={p.node} mostPopular={p.node.id === 'price_1IkUHiKWXPdgIXd83G7M506s'} />
        </div>
      ))}
    </div>
  );
};

export default GiftVoucherProducts;
