import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Divider from '../components/sections/sectionDivider';
import HeadingSection from '../components/sections/headingSection';
import PageIntro from '../components/sections/pageIntro';
import GiftVoucherProducts from '../components/gift-vouchers/gift-vouchers';

const GiftVouchers = ({ data }) => {
  return (
    <Layout>
      <SEO title="Gift Vouchers" />
      <HeadingSection heading="Gift Vouchers" subheading="Give the gift of guitar" />
      <Divider />
      <PageIntro
        text="A guitar lesson can make for an incredible gift experience, to reignite the spark of a lost passion or to begin a life long musical journey. Order your voucher below for a truly special gift! "
        subtext="Vouchers are delivered as a personalised e-card that can be customised before purchase."
      />
      <div className="page-section-padding bg-primary-lightest overflow-hidden">
        <GiftVoucherProducts products={data.prices.edges} />
      </div>
    </Layout>
  );
};

export default GiftVouchers;

export const query = graphql`
  query GiftVouchers {
    prices: allStripePrice(filter: { active: { eq: true } }, sort: { fields: unit_amount, order: ASC }) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            description
            metadata {
              inclusion1
              inclusion2
              inclusion3
            }
          }
        }
      }
    }
  }
`;

GiftVouchers.propTypes = {
  data: PropTypes.shape({
    prices: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
  }),
};
