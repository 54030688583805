import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { StaticImage } from 'gatsby-plugin-image';

import { ContrastSubmitButton, PrimaryActionButton } from '../page-components/buttons/buttons';
import Modal from '../page-components/modal';

export type IProduct = {
  id: string;
  active: boolean;
  currency: 'GBP';
  unit_amount: number;
  product: {
    id: string;
    name: string;
    description: string;
    metadata: {
      inclusion1: string;
      inclusion2: string;
      inclusion3: string;
    };
  };
};

export type IValues = {
  recipientName: string;
  sender: string;
  message: string;
};

type IProps = {
  values: IValues;
  product: IProduct;
};

const VoucherPreview = ({ values, product }: IProps) => {
  return (
    <div className="w-11/12 mx-auto md:w-7/12 lg:w-2/3 py-2 px-6 text-center text-white">
      <div className="bg-primary-light h-full border-contrast border-2 relative max-w-xl mx-auto">
        <div className="relative px-5 py-2 md:py-4 w-full">
          <StaticImage
            className="absolute top-2 left-2"
            src="../../images/logos/jb-icon-white.png"
            layout="constrained"
            width={50}
            quality={100}
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            alt="Jonny Ball logo"
          />
          <h4 className="font-bold text-lg md:text-2xl text-center">Gift Voucher</h4>
        </div>
        <div className="w-3/4 mx-auto mb-32">
          {values.recipientName ? (
            <p className="italic md:text-xl mb-2 md:mb-4" data-testid="recipient-name">
              {values.recipientName}
            </p>
          ) : (
            <p className="text-xs italic mb-2 md:mb-4">Recipient name goes here</p>
          )}

          <p className="text-center md:text-xl mb-2 md:mb-4">You have been gifted:</p>
          <p className="font-semibold block mb-2 md:mb-4">{product.product.name} with Jonny Ball Guitar</p>
          {values.sender ? (
            <p className="mb-2 md:mb-4" data-testid="sender-name">
              by {values.sender}
            </p>
          ) : (
            <p className="mb-2 md:mb-4 text-xs italic">Sender name goes here</p>
          )}
          {values.message ? (
            <p data-testid="message">{values.message}</p>
          ) : (
            <p className="italic text-xs">Gift message goes here</p>
          )}
        </div>
        <div>
          <p className="text-xs mb-2 md:mb-4">
            To redeem this voucher, please contact Jonny at:
            <span className="italic block">contact@jonnyballguitar.com</span> to book your lessons.
          </p>
          <p className="text-xs mb-2">Voucher is valid for 12 months from purchase</p>
        </div>
      </div>
    </div>
  );
};

const TextInputGroup = ({ label, name, placeholder }: { label: string; name: string; placeholder: string }) => (
  <div>
    <label htmlFor={name} className="form-label">
      {label}
    </label>
    <Field name={name} id={name} type="text" className="form-input w-full" placeholder={placeholder} />
    <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
  </div>
);

const MessageInput = () => (
  <div className="w-full md:w-full">
    <label htmlFor="message" className="form-label">
      Gift Voucher Message
    </label>
    <Field
      id="message"
      style={{ height: 150 }}
      name="message"
      as="textarea"
      className="resize-none rounded w-full md:w-full py-2 px-3"
      placeholder="Enter a message for the recipient"
    />
    <ErrorMessage name="message" render={msg => <p className="error-message">{msg}</p>} />
  </div>
);

const CustomiseForm = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <Form className="w-full px-2">
      <TextInputGroup label="Recipient's Name" name="recipientName" placeholder="Who is the voucher for?" />
      <TextInputGroup label="Sender" name="sender" placeholder="Who is the voucher from?" />
      <MessageInput />
      <div className="flex items-center justify-around pt-2 pb-6">
        <ContrastSubmitButton label="Buy Now" />
        <PrimaryActionButton onClick={() => setShowModal(false)} label="Cancel" />
      </div>
    </Form>
  );
};

type IModalProps = {
  product: IProduct;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  createCheckoutSession: (values: IValues) => void;
  loading: boolean;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
};

const CustomiseVoucherModal = ({
  product,
  setShowModal,
  showModal,
  createCheckoutSession,
  loading,
  error,
  setError,
}: IModalProps) => {
  const initialValues = {
    recipientName: '',
    message: '',
    sender: '',
  };

  const schema = {
    recipientName: Yup.string().required('Required').max(100, 'Name must be less than 100 characters'),
    message: Yup.string().max(160, 'Message must be less than 160 characters'),
    sender: Yup.string().required('Required').max(100, 'Sender must be less than 100 characters'),
  };

  return (
    <Modal
      ariaLabel="Customise gift voucher"
      showModal={showModal}
      setShowModal={setShowModal}
      className="h-screen md:h-auto overflow-y-scroll"
    >
      <div className="md:flex md:flex-row md:justify-evenly">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(schema)}
          onSubmit={(values, { setSubmitting }) => {
            createCheckoutSession(values);
            setSubmitting(false);
          }}
        >
          {formikProps => {
            const { values } = formikProps;

            return (
              <>
                <VoucherPreview values={values} product={product} />
                <div className="md:w-5/12 lg:w-1/3 px-2">
                  <h5 className="py-4 px-2">
                    Vouchers are sent via email as a E-Card. Enter the recipient&apos;s details below.
                  </h5>
                  {!loading && !error && <CustomiseForm setShowModal={setShowModal} />}
                  {loading && !error && (
                    <div className="h-24 md:h-48 flex-center">
                      <p className="italic font-bold text-lg">Redirecting to checkout . . . </p>
                    </div>
                  )}

                  {!loading && error && (
                    <div className="h-24 md:h-48 flex-center flex-col">
                      <p className="italic font-bold text-lg mb-2">Oops, something went wrong . . . </p>
                      <PrimaryActionButton label="Try Again" onClick={() => setError(false)} />
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default CustomiseVoucherModal;
