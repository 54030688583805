import React, { useState } from 'react';
import axios from 'axios';
import { BsCheckCircle } from 'react-icons/bs';

import { ContrastActionButton } from '../page-components/buttons/buttons';
import CustomiseVoucherModal, { IProduct, IValues } from './customiseVoucherModal';

import getStripe from '../../utils/stripeJs';

const cardWrapperStyles = 'p-4 w-auto text-center text-primary relative';
const cardStyles = 'flex flex-col justify-between items-center shadow-2xl border-2 border-white rounded';
const cardHeaderSectionStyles = 'px-2 py-6 bg-primary-lighter w-full border-b-2 border-white relative';
const cardHeaderTextStyles = 'text-xl montserrat font-bold';
const cardBodyStyles = 'py-6 bg-primary-lightest w-full';
const cardListStyles = 'text-left px-5';
const cardListItemStyles = 'py-1 flex items-center';
const cardFooterStyles = 'py-4 px-2 bg-primary-lighter border-t-2 border-white w-full flex flex-col items-center';
const cardPriceStyles = 'tracking-wider text-xl font-bold';

const ProductCard = ({ product, mostPopular }: { product: IProduct; mostPopular: boolean }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const createCheckoutSession = async (values: IValues) => {
    setLoading(true);
    setError(false);
    const stripe = await getStripe();

    try {
      const response = await axios.post('/.netlify/functions/stripe-checkout', {
        id: product.id,
        metadata: values,
      });
      await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
      setLoading(false);
      setShowModal(false);
    } catch (err) {
      setLoading(false);
      setError(false);
    }
  };

  const formatPrice = (amount: number, currency: 'GBP') => {
    const price = Number((amount / 100).toFixed(2));
    const numberFormat = new Intl.NumberFormat(['en-GB'], {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
    return numberFormat.format(price);
  };

  const { name, metadata } = product.product;

  return (
    <>
      <div style={{ width: 350, maxWidth: '95%' }} className={cardWrapperStyles}>
        <div className="py-2 transform -rotate-45 bg-primary text-white z-20 absolute -left-5 top-10 px-8 rounded-full">
          SOLD OUT
        </div>
        <div className={cardStyles}>
          <div className={cardHeaderSectionStyles}>
            {mostPopular && (
              <span className="absolute top-2 -right-8 rounded-full bg-contrast text-white p-2 transform rotate-45 text-sm">
                Most Popular
              </span>
            )}
            <span className={cardHeaderTextStyles}>{name}</span>
          </div>
          <div className={cardBodyStyles}>
            <ul className={cardListStyles}>
              <li className={cardListItemStyles}>
                <BsCheckCircle className="mr-2 w-1/12" /> <span className="w=11/12">{metadata.inclusion1}</span>
              </li>
              <li className={cardListItemStyles}>
                <BsCheckCircle className="mr-2 w-1/12" /> <span className="w=11/12">{metadata.inclusion2}</span>
              </li>
              <li className={cardListItemStyles}>
                <BsCheckCircle className="mr-2 w-1/12" /> <span className="w=11/12">{metadata.inclusion3}</span>
              </li>
            </ul>
          </div>
          <div className={cardFooterStyles}>
            <span className={cardPriceStyles}>{formatPrice(product.unit_amount, product.currency)}</span>
            <ContrastActionButton onClick={() => setShowModal(true)} label="Order Now" disabled />
          </div>
        </div>
      </div>
      {showModal && (
        <CustomiseVoucherModal
          showModal={showModal}
          setShowModal={setShowModal}
          product={product}
          createCheckoutSession={createCheckoutSession}
          loading={loading}
          error={error}
          setError={setError}
        />
      )}
    </>
  );
};

export default ProductCard;
